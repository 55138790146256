// import OneDay from './1day.json'
import AutoDebit from './auto-debit.json'
import BigBike100 from './bigbike100.json'
// import Delivery from './delivery.json'
import EwProtect from './ew-protect.json'
import KeyChain from './keychain.json'
import MoveToCar from './movetocar4cash.json'
import PromptChai from './promptchai.json'
// import RevolvingLoan from './revolving-loan.json'

// import WelcomeBack from './welcome-back.json'
// import MemberGetMember from './mgm-4w-q1.json'
// import SmileBase from './smile-base.json'
// import SpecialRate from './special-rate.json'
// import WelcomeBack23Q2 from './welcome-back-q22023.json'
import ApplyCarLoan from './apply-car-loan.json'
import ApplyCarLoan23Q3 from './apply-car-loan-23q3.json'
import Bottle from './bottle.json'
// import TwentyYear24Q3 from './20years.json'
// import MemberGetMemberQ42024 from './member-get-member-q4-2024.json'
// import Necessary from './necessary.json'
import GiveAway24Q3 from './giveaway-2024Q3.json'
// import Prochamber from './prochamber.json'
// import TangLak90Days from './tanglak90days.json'
import ThreeThreeThree from './three-three-three.json'

// prettier-ignore
// const Promotions = [
//   ThreeThreeThree,
//   TangLak90Days, // ตั้งหลัก 90 วัน จากคาร์ ฟอร์ แคช
//   GiveAway24Q3,
//   Prochamber, // KA x Provincial Chamber campaign หอการค้าจังหวัด
//   TwentyYear24Q3,
//   Necessary,
//   BigBike100, // Big Bike
//   WelcomeBack23Q2, // ลูกค้ากรุงศรีออโต้ (Welcome Back)
//   PromptChai, // พร้อมใช้
//   AutoDebit, // จ่ายดีมีคืน (Auto Debit)
//   MoveToCar, // รถผ่อนอยู่ ก็ขอสินเชื่อได้
//   SpecialRate, // SpecialRate 0.27%
//   SmileBase, // อาชีพไหนก็ขอสินเชื่อได้
//   OneDay, // คาร์ ฟอร์ แคช โปะ (1 Day วันเดียว)
//   MemberGetMemberQ42024, // เพียงชวนเพื่อนมาร่วมเป็นครอบครัว คาร์ ฟอร์ แคช เพื่อนได้ คุณก็ได้
//   EwProtect, // C4C Protect (ดูแลดียิ่งกว่าแฟน)
//   RevolvingLoan, // revolvingload ฉุกเฉิน เบิกง่ายผ่านไลน์
//   Delivery, // Delivery Campaign (อยู่บ้านก็ตึ๊งได้)
//   KeyChain, // พวงกุญแจแมว (มาแทน ยื่นปั๊ป รับเป๋า)
//   ApplyCarLoan,
//   ApplyCarLoan23Q3, // มอบ 3 in 1 USB Cable
//   Bottle, // ของขวัญ ขวดน้ำ
// ]

const Promotions = [
  ThreeThreeThree,
  GiveAway24Q3,
  BigBike100, // Big Bike
  PromptChai, // พร้อมใช้
  AutoDebit, // จ่ายดีมีคืน (Auto Debit)
  MoveToCar, // รถผ่อนอยู่ ก็ขอสินเชื่อได้
  EwProtect, // C4C Protect (ดูแลดียิ่งกว่าแฟน)
  KeyChain, // พวงกุญแจแมว (มาแทน ยื่นปั๊ป รับเป๋า)
  ApplyCarLoan,
  ApplyCarLoan23Q3, // มอบ 3 in 1 USB Cable
  Bottle, // ของขวัญ ขวดน้ำ
]

export default Promotions
